import barba from '@barba/core';

class ResizeTitles {
  constructor() {

    this.debug = window.location.search == "?debug" ? true : false;
    // this.debug = true;
    this.windowWidth = false;

  }

  init() {

    let _self = this;

    // Resize Titles if they are too long
    this.titles = $('div[data-barba="container"]').last().find('.event-list .event-list__item__title, .t-agenda__events .t-agenda-item__title, .o-event__title');
    this.fittedTitles = $('div[data-barba="container"]').last().find('.t-festival__heading .t-festival__title span');
    if(_self.debug) console.log('ResizeTitles barba leave', $('div[data-barba="container"]').last(), _self.titles, _self.fittedTitles);
    if(this.titles.length || this.fittedTitles.length) {

      $(window).on('resize.resizetitles', _.debounce(this._resize.bind(this), 400));
      $(window).on('load.resizetitles', function(){
        _self._resize();

        barba.hooks.leave(() => {

          if(_self.debug) console.log('ResizeTitles barba leave', _self, _self.titles);
          // empty titles
          _self.titles = null;
          _self.fittedTitles = null;
    
          // remove resize event listener
          $(window).off('resize.resizetitles');
    
        });
    
        barba.hooks.enter(() => {

          _self.init();
    
        });

      });

    }


    window.ResizeTitles = this;

  }
  
  reduceFontSize(title,targetNumLines){

    let _self = this;

    var step = 1;
    var titleHeight = $(title).get(0).scrollHeight;
    var titleLineHeight = parseFloat($(title).css('line-height'));
    var numLines = Math.floor(titleHeight / titleLineHeight);
    var targetHeight = Math.ceil(targetNumLines * titleLineHeight);
    // if(titleHeight > targetHeight) {
    if (numLines > targetNumLines) {
      let titleFontSize = parseFloat(title.style.fontSize != '' ? title.style.fontSize : $(title).css('font-size'));
      if(_self.debug) console.log($(title).html(), 'titleHeight', titleHeight, 'titleLineHeight', titleLineHeight, 'targetNumLines', targetNumLines, 'numLines', numLines, 'titleFontSize', titleFontSize, 'targetHeight', targetHeight);
      $(title).css('font-size', (titleFontSize - step) + 'px');
      window.setTimeout(function(){
        _self.reduceFontSize(title,targetNumLines);
      }, 1);
    }

  }

  resizeTitles(){

    let _self = this;
    let targetNumLines = window.innerWidth < 768 ? 3 : 3;
    let step = 1;

    _self.titles.each(function(i,title){
      //reset title font sizes
      $(title).css('font-size', '');
      targetNumLines = $(title).hasClass('o-event__title') ? (window.innerWidth < 768) ? 3 : 2 : targetNumLines;

      let titleLineHeight = parseFloat($(title).css('line-height'));
      let titleFontSize = parseFloat($(title).css('font-size'));
      let titleHeight = $(title).get(0).scrollHeight;
      let targetHeight = Math.ceil(targetNumLines * titleLineHeight);
      if(_self.debug) console.log($(title).html(), 'titleHeight', titleHeight, 'targetNumLines', targetNumLines, 'titleLineHeight', titleLineHeight, 'titleFontSize', titleFontSize, 'targetHeight', targetHeight);

      // //if is greater than target number of lines
      if(titleHeight > targetHeight) {
        let titleFontSizeLineHeightRatio = titleLineHeight / titleFontSize;
        let titleFontSizePredicted = targetHeight / targetNumLines * titleFontSizeLineHeightRatio;
        if(_self.debug) console.log($(title).html(), 'titleFontSizeLineHeightRatio', titleFontSizeLineHeightRatio, 'titleFontSizePredicted', titleFontSizePredicted);
        $(title).css('font-size', Math.ceil(titleFontSizePredicted) + 'px');
        // TODO estimate number of lines Math.floor($('.o-event__title').get(0).scrollHeight/parseFloat($('.o-event__title').css('line-height')))
        window.setTimeout(function(){
          _self.reduceFontSize(title,targetNumLines);
        }, 1);
        // _self.reduceFontSize(title,targetNumLines);
        // reduce font size until not overflown
        // while (titleHeight > targetHeight) {
        //   titleFontSize = parseFloat(title.style.fontSize != '' ? title.style.fontSize : $(title).css('font-size'));
        //   $(title).css('font-size', (titleFontSize - step) + 'px');
        //   titleLineHeight = parseFloat($(title).css('line-height'));
        //   targetHeight = Math.ceil(targetNumLines * titleLineHeight);
        //   titleHeight = $(title).height();
        //   if(_self.debug) console.log($(title).html(), 'titleHeight', titleHeight, 'titleLineHeight', titleLineHeight, 'titleFontSize', titleFontSize, 'targetHeight', targetHeight);
        // }
      }
    });
  }

  fitTitles(){

    let _self = this;
    let targetNumLines = 1;
    let step = 1;

    this.fittedTitles.each(function(i,title){
      //reset title font sizes
      $(title).css('font-size', '');
      // targetNumLines = $(title).attr('data-target-num-lines') || targetNumLines; 

      let titleLineHeight = parseFloat($(title).css('line-height'));
      let titleFontSize = parseFloat(title.style.fontSize != '' ? title.style.fontSize : $(title).css('font-size'));
      let titleHeight = $(title).height();
      let targetHeight = Math.ceil(targetNumLines * titleLineHeight);
      if(_self.debug) console.log($(title).html(), 'titleHeight', titleHeight, 'titleLineHeight', titleLineHeight, 'titleFontSize', titleFontSize, 'targetHeight', targetHeight);


      let titleWidth = $(title).width();
      let targetWidth = $(title).parent().width();
      if(titleWidth < targetWidth) {
        // increase font size until not overflown
        while (titleWidth < (targetWidth - step)) {
          titleFontSize = parseFloat(title.style.fontSize != '' ? title.style.fontSize : $(title).css('font-size'));
          $(title).css('font-size', (titleFontSize + step) + 'px');
          titleWidth = $(title).width();
          if(_self.debug) console.log($(title).html(), 'titleWidth', titleWidth, 'titleFontSize', titleFontSize, 'targetWidth', targetWidth);
        }
      }
      
      titleWidth = $(title).width();
      if(titleWidth > targetWidth) {
        // increase font size until not overflown
        while (titleWidth > targetWidth) {
          titleFontSize = parseFloat(title.style.fontSize != '' ? title.style.fontSize : $(title).css('font-size'));
          $(title).css('font-size', (titleFontSize - step) + 'px');
          titleWidth = $(title).width();
          if(_self.debug) console.log($(title).html(), 'titleWidth', titleWidth, 'titleFontSize', titleFontSize, 'targetWidth', targetWidth);
        }
      }

    });

  }

  _resize(e) {

    // if(this.debug) console.log('ResizeTitles _resize window.innerWidth', window.innerWidth, 'this.windowWidth', this.windowWidth);
    
    let newWindowWidth = window.innerWidth;

    if(!this.windowWidth || newWindowWidth != this.windowWidth) {

      this.windowWidth = newWindowWidth;

      if(this.titles.length) this.resizeTitles();
      
      if(this.fittedTitles.length) this.fitTitles();

    }

  }

}

export { ResizeTitles };