class BackgroundGymnase {

	constructor() {
		
	}

	init() {
		document.addEventListener('mousemove', onMouseUpdate, false);
		document.addEventListener('mouseenter', onMouseUpdate, false);
  
		const mouse = { x: (window.innerWidth / 2), y: (window.innerHeight / 2) };
  
		function onMouseUpdate(e) {
			mouse.x = e.pageX - window.scrollX;
			mouse.y = e.pageY - window.scrollY;
		}
  
		function iteration(chrono) {
			$('.site-background').get(0).style.setProperty("--positionX", (mouse.x - 200) + 'px');
			$('.site-background').get(0).style.setProperty("--positionY", (mouse.y - 200) + 'px');

			window.requestAnimationFrame(iteration)
		}
		
		window.requestAnimationFrame(iteration)
	}
}

export { BackgroundGymnase };
  