import { Agenda } from './agenda.js';
import { Animations } from './animations.js';
import { BackgroundGymnase } from './background.js';
import { EventsSlider } from './events-slider.js';
import { FooterGymnase } from './footer.js';
import { Gallery } from './gallery.js';
import { Home } from './home.js';
import { HybridSelect } from './hybrid-select.js';
import { MenuGymnase } from './menu.js';
import { ParallaxEffect } from './parallax-effect.js';
import { RelatedSlider } from './related-slider.js';
import { SelectSlider } from './select-slider.js';
import SingleEvent from './event.js';
import { TextLoop } from './text-loop.js';
import { ResizeTitles } from './resize-titles.js';
import { News } from './news.js';
import barba from '@barba/core';

(function() {
  "use strict";



  class Main {
    constructor() {

      this.debug = window.location.search === "?debug";
      // this.debug = true;

      const _menu = new MenuGymnase();
      _menu.init();

      const _footer = new FooterGymnase();
      _footer.init();

      const _background = new BackgroundGymnase();
      _background.init();

      let _self = this;

      _self.setVw();
      window.addEventListener('resize', _self.setVw);

      $(window).on('load', function(e){
        $('html').removeClass('loading');
      });

      // Fix translation switch to keep url query params
      $('.o-menu__languages .lang-item a').each(function() {
        const href = $(this).attr('href');

        $(this).attr('data-href', href);
        $(this).attr('href', '#');

        $(this).click(function(e) {
          e.preventDefault();

          window.location = $(this).attr('data-href') + window.location.search;
        })
      })


      function pageLoadScripts(){

        const _singleEvent = new SingleEvent();
        _singleEvent.init();

        const _gallery = new Gallery();
        _gallery.init();

        const _animations = new Animations();
        _animations.init();

        // const _tabs = new Tabs();
        // _tabs.init();

        const _home = new Home();
        _home.init();

        const _eventsSlider = new EventsSlider();
        _eventsSlider.init();

        const _parallaxEffect = new ParallaxEffect();
        _parallaxEffect.init();

        const _agenda = new Agenda();
        _agenda.init();

        const _hybridSelect = new HybridSelect();
        _hybridSelect.init();

        const _selectSlider = new SelectSlider();
        _selectSlider.init();

        const _relatedSlider = new RelatedSlider();
        _relatedSlider.init();

        const _textLoop = new TextLoop();
        _textLoop.init();

        const _resizeTitles = new ResizeTitles();
        _resizeTitles.init();

        const _news = new News();
        _news.init();

        // let _self = this;
        // add class to blocks with video url
        $(".has-video-url .wp-block-media-text__media a").addClass('js-modal-btn');
        $(".js-modal-btn").each(function(i,el){
          // console.log('js-modal-btn', el, $(el).attr('data-video-id'), $(el).attr('href'));
          if(typeof $(el).attr('data-video-id') == 'undefined' ||  $(el).attr('data-video-id') == '') {
            $(el).attr('data-video-id', _self.mkwvs_get_video_url_id($(el).attr('href')));
          }
          let params = /\D/.test( $(el).attr('data-video-id') ) ? {channel: 'youtube', autoplay: '1'} : {channel: 'vimeo', autoplay: true}
          // console.log('js-modal-btn params', params);
          $(el).modalVideo(params);
        });
        $(".o-landing .wp-block-image:not(.swiper-slide), #page-content:not(.t-home__news) .wp-block-image:not(.swiper-slide), #page-content:not(.t-home__news) .wp-block-media-text__media").each(function(i,el){
          $(el).addClass('zoomable').on('click', function(e){
            let theImg = $(this).find('img');
            let imgCaption = ($(this).find('figcaption').length > 0) ? $(this).find('figcaption').text() : theImg.attr('alt') || '';
            Spotlight.show([{
              src: theImg.attr('src'),
              title: imgCaption,
            }]);
          });
        });

      }


      pageLoadScripts();

      const loadingPanel = $('#loading').appendTo(document.body);
      if(_self.debug) console.log('scrollRestoration?', 'scrollRestoration' in history, history.scrollRestoration);
      if ('scrollRestoration' in history) { history.scrollRestoration = 'manual'; }

      let scrollX = 0;
      let scrollY = 0;

      let wpAdminBar = $('#wpadminbar');
      if(wpAdminBar.length) {
        $('#wpadminbar').attr('data-barba-prevent', 'all');
      }

      barba.init({
        timeout: 20000,
        debug: _self.debug,
        prefetchIgnore: '/wp-admin/',
        prevent: ({ el }) => (el.classList && el.classList.contains('ab-item')),
        transitions: [
          {
            name: 'default-transition',
            leave(data) {
              loadingPanel.one('animationend webkitanimationEnd', function(e){
                // loadingPanel.removeClass('is-entering');
              });

              loadingPanel.addClass('is-animated').addClass('is-entering');

              $('html').addClass('loading');

              if($('#menu-open').attr('aria-expanded') == "true") {
                $('#menu-open').trigger('click');
              }
              //
              // gsap.to(loadingPanel, 0.25, {
              //   yPercent: -100,
              //   force3D: true,
              //   onStart: function(){
              //
              //     console.log('barba default transition start', $('.a2a_floating_style:visible'));
              //     $('.a2a_floating_style:visible').css('display', 'none');
              //
              //     // gsap.to(loadingPanel.find('.panel').eq(0), 0.5, {
              //     //   delay: 0.5,
              //     //   yPercent: -100,
              //     //   force3D: true,
              //     //   onComplete: function(){
              //     //     gsap.set(loadingPanel.find('.panel').eq(0), { yPercent: 100 })
              //     //     loadingPanel.addClass('is-animated');
              //     //   },
              //     // });
              //   },
              //   onComplete: function(){
              //
              //     // close menu if open
              //     if($('#menu-open').attr('aria-expanded') == "true") {
              //       $('#menu-open').trigger('click');
              //     }
              //
              //     // reset scroll
              //     // console.log('reset scroll', scrollX, scrollY, ScrollTrigger.getAll());
              //     // window.scrollTo(scrollX, scrollY);
              //     window.scrollTo(0, 0);
              //
              //   }
              // });

              return gsap.to(data.current.container, 0, {
                delay: 0.25,
                opacity: 0,
                onComplete: function(){
                  // console.log('barba leave', ScrollTrigger.getAll());
                  let scrollTriggers = ScrollTrigger.getAll();
                  for (var i = 0; i < scrollTriggers.length; i++) {
                    scrollTriggers[i].kill(false);
                  }
                  // reset scroll
                  // window.scrollTo(0, 0);
                  window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                  });
                }
              });
            },
            enter(data) {

              $(document.body).attr('class', $(data.next.container).data('barba-body_class'));



              // loadingPanel.removeClass('is-animated');
              // gsap.to(loadingPanel.find('.panel').eq(0), 0.5, {
              //   delay: (0.5+1),
              //   force3D: true,
              //   yPercent: 0
              // });

              // gsap.to(loadingPanel, 0.25, {
              //   delay: 0.25,
              //   force3D: true,
              //   yPercent: -200,
              //   onStart: function(){
              //   },
              //   onComplete: function(){
              //     gsap.set(loadingPanel, { yPercent: 0 })
              //     loadingPanel.removeClass('is-animated');
              //   }
              // });

              return gsap.from(data.next.container, 0.1, {
                delay: 0.25,
                opacity: 0,
                onComplete: function(){
                  // console.debug('complete')

                  loadingPanel.one('animationend webkitanimationEnd', function(e){
                    loadingPanel.removeClass('is-animated').removeClass('is-entering is-leaving');
                  });
                  loadingPanel.addClass('is-leaving');

                  window.ResizeTitles._resize();
                }
              });

            },
            after(data){
              // console.log('barba after');

              setTimeout(function(){
                ScrollTrigger.refresh();
                // console.log('barba after scrolltriggers refreshed', ScrollTrigger.getAll());
              }, 2000);

              // update wp admin bar if present
              if(wpAdminBar.length) {
                wpAdminBar.load(data.next.url.href + ' #wpadminbar > *');
              }

              pageLoadScripts();
            }
          },
          // {
          //   name: 'agenda-transition',
          //   from: {
          //     namespace: [
          //       'agenda'
          //     ]
          //   },
          //   to: {
          //     namespace: [
          //       'agenda'
          //     ]
          //   },
          //   leave(data) {
          //     console.log('agenda-transition leave', data);
          //   },
          //   enter(data) {
          //     console.log('agenda-transition enter', data);
          //     // pageLoadScripts();
          //   }
          // }
        ]
      });


      barba.hooks.before((e) => {

        if(_self.debug) console.log('Barba before event', e);
        // check if language changes
        let langCurrent = $('html').attr('lang');
        if(_self.debug) console.log('Barba before LANG CHANGE?', langCurrent, e.next.url.path);
        const regexNextURL = new RegExp('^\\/en\\/', 'gm');
        if( (langCurrent == 'fr-FR' && regexNextURL.test(e.next.url.path) == true) || (langCurrent == 'en-GB' && regexNextURL.test(e.next.url.path) == false) ) {
          if(_self.debug) console.log('Barba before LANG CHANGE', langCurrent + ' -> other lang');
          barba.force(e.next.url.href);
        }
        $(document).trigger({type:"barbaBefore", detail: {barbaEvent: e}});

      });

      barba.hooks.leave((e) => {

        if(_self.debug) console.log('Barba leave event', e);

        // record scroll position
        scrollX = barba.history.current.scroll.x;
        scrollY = barba.history.current.scroll.y;

        $(document).trigger({type:"barbaLeave", detail: {barbaEvent: e}});

      });

      barba.hooks.after((e) => {
        if(_self.debug) console.log('Barba after event', e);
        // e.trigger.dispatchEvent(new CustomEvent("barbaAfter", {
        //   detail: { event: e },
        //   bubbles: true
        // }));

        $('html').removeClass('loading');

        $(document).trigger({type:"barbaAfter", detail: {barbaEvent: e}});
      });


    }

    mkwvs_get_video_url_id(url){
    	if(url.indexOf('youtu.be') > 0 || url.indexOf('youtube') > 0) {
    		var regex = /(?:youtube(?:-nocookie)?\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/i;
    		var found = url.match(regex);
    		return found[1];
    	} else if(url.indexOf('vimeo') > 0) {
    		var videoURL = new URL(url);
    		return videoURL.pathname.substring(1);
    	} else {
    		return 'unknown';
    	}
    }

    setVw() {
      let vw = document.documentElement.clientWidth / 100;
      document.documentElement.style.setProperty('--vw', `${vw}px`);
      let vh = document.documentElement.clientHeight / 100;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }


  }

  const _main = new Main();


}());
