class FooterGymnase {

	constructor() {
		
	}

	init() {
		$('.sib_signup_form input').on('focus blur change', function(event) {
			if($(this).val() === '') {
				$(this).parent().removeClass('has-focus')
			} else {
				$(this).parent().addClass('has-focus')
			}
		})
	}
}

export { FooterGymnase };
  