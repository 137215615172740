class ParallaxEffect {

  constructor() {

    this.debug = window.location.search == "?debug" ? true : false;
    // this.debug = true;
    this.pageReady = false;
  }

  init() {

    let _self = this;


    if(!$('html').hasClass('loading')) {
      this.pageReady = true;
    }

    let parallaxes = $('.has-parallax');
    if(_self.debug) console.log('ParallaxEffect parallaxes?', parallaxes);
    if(parallaxes.length) {

      this.parallaxTimelines = [];

      parallaxes.each(function(i,parallax){
        if(_self.debug) console.log('ParallaxEffect parallax', parallax);
        if(_self.debug) console.log('ParallaxEffect parallax-target', $(parallax).find('*[data-parallax]'));

        // var theParallaxAnimation;
        // let parallaxAnimation = $(parallax).find('.a-animation');
        // if(parallaxAnimation.length) {
          // theParallaxAnimation = eval('anim'+parallaxAnimation.attr('id'));
          // if(_self.debug) console.log('ParallaxEffect animation?', theParallaxAnimation);
        // }
        let parallaxToggleActions = $(parallax).attr('data-parallax-toggle-actions');


        let parallaxTimeline = gsap.timeline({
          ease: "none",
          scrollTrigger: {
            id: 'parallaxEffect-'+i,
            paused: !_self.pageReady,
            trigger: parallax,
            start: "top bottom",
            end: "bottom top",
            scrub: $(parallax).attr('data-parallax-scrub') ? $(parallax).attr('data-parallax-scrub') : !$(parallax).hasClass('has-parallax--noscrub'),
            markers: _self.debug,
            onEnter: function(progress, direction, isActive){
              // if(theParallaxAnimation) {
                // theParallaxAnimation.setDirection(1);
                // theParallaxAnimation.goToAndStop(0);
                // setTimeout(function(){
                  // theParallaxAnimation.play();
                // },500);
              // }
            },
            onEnterBack: function(progress, direction, isActive){
            // if(_self.debug) console.log('ParallaxEffect onEnterBack?', theParallaxAnimation);
              // if(theParallaxAnimation) {
                // theParallaxAnimation.setDirection(-1);
                // theParallaxAnimation.goToAndStop(0);
                // setTimeout(function(){
                  // theParallaxAnimation.play();
                // },500);
              // }
            },
            toggleActions: parallaxToggleActions ? parallaxToggleActions : $(parallax).hasClass('has-parallax--noscrub') ? "play none none reverse" : "play none none none"
          },
        });

        ScrollTrigger.matchMedia({

          "(min-width: 768px)": function() {
            $(parallax).find('*[data-parallax]').each(function(i,parallaxTarget){
              if(_self.debug) console.log('data-parallax', $(parallaxTarget).attr('data-parallax'));
              let parallaxParams = JSON.parse($(parallaxTarget).attr('data-parallax'));
              if(_self.debug) console.log('parallaxParams', parallaxParams);
              parallaxTimeline.fromTo(parallaxTarget, parallaxParams[0], parallaxParams[1], 0);
            });
          },
          "(max-width: 767px)": function() {
            $(parallax).find('*[data-parallax-mob]').each(function(i,parallaxTarget){
              let parallaxParams = JSON.parse($(parallaxTarget).attr('data-parallax-mob'));
              parallaxTimeline.fromTo(parallaxTarget, parallaxParams[0], parallaxParams[1], 0);
            });
          }

        });

        _self.parallaxTimelines.push(parallaxTimeline);

      });

      $(window).on('load', function(e){
        _self.pageReady = true;
        $.each(_self.parallaxTimelines, function(i,tl){
          if(_self.debug) console.log('ParallaxEffect resume timeline!', tl)
          tl.resume();
        });
      });

    }
  }
}

export { ParallaxEffect };
