class News {

  constructor() {

  }

  init() {
    // console.log('news init');
    if($('.page-template-news').length) {
      // console.log($('.actus--list'));


      // $('.actus--list').append( '<span class="load-more load-more--loading"></span>' );
      var list_blog = $('.o-list-blog');
      var button = $('#news-load-more');
      var page = 2;
      var loading = false;
      var scrollHandling = {
        allow: true,
        reallow: function() {
          scrollHandling.allow = true;
        },
        delay: 1200 //(milliseconds) adjust to the highest acceptable value
      };
      var timelineBtn = new gsap.timeline({paused:true});



      timelineBtn.to(button.find('svg > path'), 2,
      {
        rotation: 360,
        transformOrigin:"center",
        svgOrigin:"25 25",
        ease: Power2.easeInOut,
        repeat: -1
      });
      function loadNewsOnScroll(){
        if( ! loading && scrollHandling.allow ) {
          scrollHandling.allow = false;
          setTimeout(scrollHandling.reallow, scrollHandling.delay);
          var offset = $(button).offset().top - $(window).scrollTop();
          if( 1200 > offset ) {
            loading = true;
            button.addClass('load-more--loading');
            var data = {
              action: 'mw_ajax_news_load_more',
              nonce: mwnewsloadmore.nonce,
              page: page,
              // query: mwloadmore.query,
            };
            // console.log();
            timelineBtn.play();
            $.post(scriptsJsObject.ajaxurl.replace('https:', ''), data, function(res) {
              if( res.success) {
                if(res.data != "") {
                  $(res.data).insertBefore( button );
                  page = page + 1;

                  setTimeout(function(){
                    loading = false;
                    timelineBtn.pause();
                    button.removeClass('load-more--loading');
                  }, 500);
                } else {
                  //no data, reached news end.
                  $(window).off('scroll', loadNewsOnScroll);
                  timelineBtn.pause();
                  button.removeClass('load-more--loading');
                }
              } else {
                // console.log(res);
              }
            }).fail(function(xhr, textStatus, e) {
              // console.log(xhr.responseText);
            });

          }
        }
      }

      $(window).scroll(loadNewsOnScroll);

    }

  }
}

export { News };