class SelectSlider {
  constructor() {

    this.debug = window.location.search == "?debug" ? true : false;
    // this.debug = true;
    this.selectSliderSwipers = [];
  }

  init() {

    let _self = this;

    if(typeof window.SelectSlider != 'undefined') window.SelectSlider.destroy();

    if(_self.debug) console.log('selectSlider init', $('.o-selectSlider-mozart__list'));
    let selectSliders = $('.m-select-slider');

    if(selectSliders.length) {

      let firstLoad = true;

      selectSliders.each(function(i,selectSlider){

        if(_self.debug) console.log('selectSlider?', selectSlider);
        if(_self.debug) console.log('selectSlider prev?', $(selectSlider).find('.m-select-slider__prev'));
        if(_self.debug) console.log('selectSlider next?', $(selectSlider).find('.m-select-slider__next'));
        if(_self.debug) console.log('selectSlider initialSlide?', $(selectSlider).find('.swiper-slide.is-current').index());

        const selectSliderSwiper = new Swiper(selectSlider, {
          slidesPerView: 1,
          runCallbacksOnInit: false,
          initialSlide: $(selectSlider).find('.swiper-slide.is-current').index(),
          // navigation: {
          //   prevEl: $(selectSlider).find('.m-select-slider__prev'),
          //   nextEl: $(selectSlider).find('.m-select-slider__next'),
          // },
          navigation: {
            prevEl: $(selectSlider).find('.m-select-slider__prev').get(0),
            nextEl: $(selectSlider).find('.m-select-slider__next').get(0),
          },
          on: {
            'slideChangeTransitionEnd': function () {
              if(_self.debug) console.log('slideChangeTransitionEnd', this, this.activeIndex);

              firstLoad = false;

              if ($('.page-template-agenda').length > 0 ){ // Filter Agenda Template On YearMonth Slider Changed
                _self.filter_agenda_events();
              }
            },
          }
        });

        if($(selectSlider).hasClass('year-month')) {
          window.SelectSlider = selectSliderSwiper;
          if(firstLoad) {
            firstLoad = false;

            // sort by hour on first load
            window.Agenda.sortRepresentationByFirstHour();
          }
        }

        // Manage Dropdown Filters On Change
        $('#agendaFilterZones').on('change', _self.filter_agenda_events);
        $('#agendaFilterPublics').on('change', _self.filter_agenda_events);

        $('.t-agenda__nav .btn-arrow-right').on('click',function(e){
          e.preventDefault();
          e.stopPropagation();
          selectSliderSwiper.slideNext();
        });

        $('.t-agenda__nav .btn-arrow-left').on('click',function(e){
          e.preventDefault();
          e.stopPropagation();
          selectSliderSwiper.slidePrev();
        });


      });

    }

    function getTransitionEndEventName() {
      var transitions = {
          "transition"      : "transitionend",
          "OTransition"     : "oTransitionEnd",
          "MozTransition"   : "transitionend",
          "WebkitTransition": "webkitTransitionEnd"
       }
      let bodyStyle = document.body.style;
      for(let transition in transitions) {
          if(bodyStyle[transition] != undefined) {
              return transitions[transition];
          }
      }
    }

    this.transitionEndEventName = getTransitionEndEventName();

  }

  /* Generic Filter Method */
  filter_agenda_events(){
    window.Agenda.filter_agenda_events();
  }

}

export { SelectSlider }
