class Home {
  constructor() {

      this.debug = window.location.search == "?debug" ? true : false;
      // this.debug = true;

  }

  init() {

    let _self = this;

    if(_self.debug) console.log('Home init');

    _self.homeLogo = $('body.page-template-home.is-not-festival div[data-barba="container"]').last().find('.t-home__heading .t-home__logo');

    if(_self.homeLogo.length) {

      let ev = ($._data(document, 'events'));
      if (typeof ev === "undefined" || (!ev.barbaAfter || ev.barbaAfter.filter(e => e.namespace === 'Home').length == 0) || (!ev.barbaLeave || ev.barbaLeave.filter(e => e.namespace === 'Home').length == 0)) {
        $(document).on("barbaAfter.Home", this.barbaAfterHandler.bind(this));
        $(document).on("barbaLeave.Home", this.barbaLeaveHandler.bind(this));
      }
      $(window).off('load.HomeLogo');
      // $(window).on('load.HomeLogo', this.switchTheLogo.bind(this));
      $(window).on('load.HomeLogo', this.bringTheLogoDown.bind(this));


    }

  }

  barbaAfterHandler(event){
    if(this.debug) console.log("Hello from barbaAfter.Home", event, event.target, this); // Hello from after the barba transition
    // if(event.detail.barbaEvent.next.namespace == 'home') this.switchTheLogo();
    if(event.detail.barbaEvent.next.namespace == 'home') this.bringTheLogoDown();
  }

  barbaLeaveHandler(event){
    if(this.debug) console.log("Hello from barbaLeave.Home", event, event.target, this); // Hello from after the barba transition
    if(event.detail.barbaEvent.current.namespace == 'home') _self.headerLogo.removeClass('is-hidden')
  }
  /*
  switchTheLogo(){

    if(this.debug) console.log("Home switchTheLogo", this, this.homeLogoTimeline, typeof this.homeLogoTimeline);

    let _self = this;

    _self.homeLogo = $('div[data-barba="container"]').last().find('.t-home__heading .t-home__logo');
    // _self.homeLogoContainer = _self.homeLogo.parent();
    _self.headerLogo = $('.o-header__left .m-logolink img');
    _self.headerLogoPosition = _self.headerLogo.get(0).getBoundingClientRect();
    _self.homeLogoPosition = _self.homeLogo.get(0).getBoundingClientRect();

    if(_self.debug) console.log('Home headerLogoPosition',  _self.headerLogoPosition, ' homeLogoPosition',  _self.homeLogoPosition);

    _self.homeLogo.parent().css({
      'width': _self.homeLogoPosition.width,
      'height': _self.homeLogoPosition.height
    });

    _self.homeLogo.css({
      'position': 'fixed',
      'transform-origin': "0 0",
      'left': _self.homeLogoPosition.x,
      'top': _self.homeLogoPosition.y,
      'width': _self.homeLogoPosition.width
    });

    // if(typeof _self.homeLogoTimeline != 'undefined') {
    //   _self.homeLogoTimeline.scrollTrigger.kill();
    //   _self.homeLogoTimeline.kill();
    // }
    // setTimeout(function(){


      _self.homeLogoTimeline = gsap.timeline({
        ease: "none",
        scrollTrigger: {
          id: 'homeLogo',
          // paused: !_self.pageReady,
          trigger: 'div[data-barba="container"]:last-child .t-home__heading .t-home__logo-container',
          start: "top bottom-="+window.innerHeight+"px",
          // end: "bottom top+="+(homeLogoPosition.height*2)+"px",
          end: "bottom+=50% top",
          scrub: 1.5,
          markers: _self.debug,
          onEnter: function(progress, direction, isActive){
            // if(theParallaxAnimation) {
              // theParallaxAnimation.setDirection(1);
              // theParallaxAnimation.goToAndStop(0);
              // setTimeout(function(){
                // theParallaxAnimation.play();
              // },500);
            // }
          },
          onEnterBack: function(progress, direction, isActive){
          // if(_self.debug) console.log('ParallaxEffect onEnterBack?', theParallaxAnimation);
            // if(theParallaxAnimation) {
              // theParallaxAnimation.setDirection(-1);
              // theParallaxAnimation.goToAndStop(0);
              // setTimeout(function(){
                // theParallaxAnimation.play();
              // },500);
            // }
          },
          onStart: function(bla){
            if(_self.debug) console.log('Home homeLogoTimeLine start', bla);
          },
          // onScrubComplete: ({progress, direction, isActive}) => function(progress, direction, isActive){
          //   if(_self.debug) console.log('Home homeLogoTimeLine progress, direction, isActive', progress, direction, isActive);
          // },
          onScrubComplete: ({progress, direction, isActive}) => function(progress, direction, isActive){
            if(_self.debug) console.log('Home homeLogoTimeLine progress', progress, 'direction', direction, 'isActive', isActive);
          },
          toggleActions: "play none none reverse"
        },
      });
      
  
      _self.homeLogoTimeline.to( _self.homeLogo.get(0), {
        "scale": _self.headerLogoPosition.width / _self.homeLogoPosition.width,
        "x": _self.headerLogoPosition.x - _self.homeLogoPosition.x,
        "y": _self.headerLogoPosition.y - _self.homeLogoPosition.y
      }, 0);

    // },0);

    

  }
  */


  bringTheLogoDown(){

    if(this.debug) console.log("Home bringTheLogoDown", this, this.homeLogoTimeline, typeof this.homeLogoTimeline);

    let _self = this;

    _self.headerLogo = $('.o-header__left .m-logolink img');

    _self.homeLogoTimeline = gsap.timeline({
      ease: "none",
      scrollTrigger: {
        id: 'homeLogo',
        trigger: 'div[data-barba="container"]:last-child .t-home__heading .t-home__logo-container',
        start: "bottom+=50% top",
        end: "bottom+=150% top",
        scrub: false,
        markers: _self.debug,
        toggleActions: "play none none reverse",
        // onToggle: self => console.log("toggled, isActive:", self.isActive),
        onEnter: self => _self.headerLogo.addClass('is-brought-down'),
        onEnterBack: self => _self.headerLogo.removeClass('is-brought-down', self.isActive),
        // onToggle: function(self){
        //   console.log("toggled. active?", self.isActive)
        //   _self.headerLogo.toggleClass('is-brought-down', self.isActive);
        // }
      },
    });
      
  
    // _self.homeLogoTimeline.fromTo( _self.headerLogo.get(0),
    //   {
    //     "y": -82,
    //   },
    //   {
    //     "y": 0,
    //     "duration": 0.4
    //   }, 0);
    

  }

}

export { Home };
