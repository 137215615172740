export default class SingleEvent {

  constructor() {

    this.debug = window.location.search == "?debug" ? true : false;
    // this.debug = true;
    window.Event = this;

  }

  init() {

    if(this.debug) console.log('SingleEvent');

    // document.removeEventListener("barbaAfter", this.barbaAfterHandler);
    let ev = ($._data(document, 'events'));
    if (typeof ev === "undefined" || !ev.barbaAfter || ev.barbaAfter.filter(e => e.namespace === 'SingleEvent').length == 0) {
      $(document).on("barbaAfter.SingleEvent", this.barbaAfterHandler.bind(this));
    }
    $(window).on('load', this.scrollToSelectedDate.bind(this));

    $('#school-event').on('change', function(event) {

      if($(this).is(':checked')) {
        console.debug('school filter', $(this))
        $('.t-event__booking .event_calendar_item:not(.is-school)').hide()
        $('.t-event__booking .event_calendar_item .event_calendar_item__time span:not(.is-school)').hide()
      } else {
        $('.t-event__booking .event_calendar_item .event_calendar_item__time span').show()
        $('.t-event__booking .event_calendar_item').show()
      }
    })

    $('figure button[aria-expanded]').on('click', function(event) {
      event.preventDefault()
      event.stopPropagation()

      const expanded = $(this).attr("aria-expanded")
      const controls = $(this).attr("aria-controls")

      if (expanded === "true") {
        $(this).attr('aria-expanded', 'false')
      } else {
        $(this).attr('aria-expanded', 'true')
      }
    })

    $('.event_calendar_item').on('click', function(event) {
      event.preventDefault()
      event.stopPropagation()

      const expanded = $(this).attr("aria-expanded")
      const controls = $(this).attr("aria-controls")

      $(`.event_calendar_item[aria-controls!="${controls}"]`).attr('aria-expanded', 'false')
      $(`.event_calendar_reservation_item[aria-controls!="${controls}"]`).removeClass('is-expanded')

      if (expanded === "true") {
        $(`.event_calendar_item[aria-controls="${controls}"]`).attr('aria-expanded', 'false')
        $(`#${controls}`).removeClass('is-expanded')
      } else {
        $(`.event_calendar_item[aria-controls="${controls}"]`).attr('aria-expanded', 'true')
        $(`#${controls}`).addClass('is-expanded')
      }
    })
  }

  barbaAfterHandler(event){
    if(this.debug) console.log("Hello from barbaAfter.SingleEvent", event, event.target, this); // Hello from after the barba transition
    if(event.detail.barbaEvent.next.namespace == 'single') this.scrollToSelectedDate();
  }

  scrollToSelectedDate(){

    // scroll to selected date if necessary
    let selectedDate = $('div[data-barba="container"]').last().find('.t-event__booking__list__content button[aria-expanded="true"]');
    if (selectedDate.length){
      setTimeout(function(){
        selectedDate.get(0).scrollIntoView({block: 'nearest', behavior: 'smooth'});
      },200);
    }

  }

}
