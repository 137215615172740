class Gallery {

  constructor() {

    this.debug = window.location.search == "?debug" ? true : false;

  }

  init() {

    let _self = this;

    let galleries = $('.o-gallery');
    if(_self.debug) console.log('Gallery init', galleries.find('.swiper-slide'));
    if(galleries.length && galleries.find('.swiper-slide').length > 1) {
      galleries.each(function(i,gallery){
        if(_self.debug) console.log('gallery?', gallery);
        let gallerySwiper = new Swiper($(gallery).find('.o-gallery__list').get(0), {
          slidesPerView: 'auto',
          autoHeight: false,
          // slidesPerColumn: 2,
          spaceBetween: 20,
          threshold: 20,
          // slidesPerGroup: 2,
          // centeredSlides: true,
          // spaceBetween: -5,
          // loop: true,
          // loopFillGroupWithBlank: true,
          // zoom: true,
          navigation: {
            nextEl: $(gallery).find('.o-gallery__next').get(0),
            prevEl: $(gallery).find('.o-gallery__prev').get(0),
          },
          scrollbar: {
            el: $(gallery).find('.o-gallery__scrollbar').get(0),
            hide: false,
            draggable: true,
            dragSize: 82
          },
          breakpoints: {
            768: {
              slidesPerView: 'auto',
              spaceBetween: 24,
            },
            // 1024: {
            //   spaceBetween: -10,
            // },
            1440: {
              spaceBetween: 30,
            },
          }

        });
        if(_self.debug) console.log('Gallery initialized ?', gallerySwiper);
      });
    }



  }
}

export { Gallery };
