class MenuGymnase {

  constructor() {

    this.debug = window.location.search == "?debug" ? true : false;
    // this.debug = true;
  }

  init() {
    this.menu   = $('#menu-navigation')
    this.header = $('.o-header')

    $('#menu-open').on('click', function(event) {
      $('.o-header').toggleClass('is-open');
      $(document.body).toggleClass('has-menu-open');
    })
    
    $('.o-header [aria-expanded]').on('click', function(event) {
      event.preventDefault()
      event.stopPropagation()

      const expanded = $(this).attr("aria-expanded")
      const controls = $(this).attr("aria-controls")

      $(`[aria-controls!="${controls}"]:not(.menu-toggle)`).attr('aria-expanded', 'false')

      if (expanded === "true") {
        $(`[aria-controls="${controls}"]`).attr('aria-expanded', 'false')
      } else {
        $(`[aria-controls="${controls}"]`).attr('aria-expanded', 'true')
      }
    });

    let ev = ($._data(document, 'events'));
    if (typeof ev === "undefined" || !ev.barbaAfter || ev.barbaAfter.filter(e => e.namespace === 'MenuGymnase').length == 0) {
      $(document).on("barbaAfter.MenuGymnase", this.barbaAfterHandler.bind(this));
    }

  }

  closeMenus() {
    
  }

  _resize() {

  }

  barbaAfterHandler(event){
    if(this.debug) console.log("Hello from barbaAfter.MenuGymnase", event, event.target, event.detail.barbaEvent); // Hello from after the barba transition
    this.updateMenus(event.detail.barbaEvent);
  }

  updateMenus(eventDetail) {

    let newMenuCurrentItems = this.menu.find('a[href$="'+eventDetail.next.url.href.replace(/http(s)?\:\/\/[^\/]+/gm, ``)+'"]');
    if(this.debug) console.log('MenuGymnase updateMenus', eventDetail.next.url.href, 'a[href="'+eventDetail.next.url.href.replace(/http(s)?\:\/\/[^\/]+/gm, ``)+'"]', newMenuCurrentItems);

    // remove previous current classes
    this.menu.find('li.current-menu-ancestor, li.current-menu-item').removeClass('current-menu-ancestor current-menu-parent current-menu-item current_page_item current_page_parent current_page_ancestor');
    // remove previous current aria attributes
    this.menu.find('[aria-current="page"]').removeAttr('aria-current');

    // update 1st level parent items
    newMenuCurrentItems.parents('.menu-item-has-children').addClass('current-menu-ancestor current-menu-parent');
    // update 2nd level parent items
    newMenuCurrentItems.parents('.page_item').addClass('current-menu-ancestor current-menu-parent current-menu-item current_page_item current_page_parent current_page_ancestor');

    // add new current aria attributes
    newMenuCurrentItems.attr('aria-current', 'page');

    // check language
    if($(document.body).hasClass('current-lang-en')) {
      $('html').attr('lang', 'en-GB');
      $('.o-menu__languages .current-lang:not(.lang-item-en)').removeClass('current-lang').find('a').blur();
      $('.o-menu__languages .lang-item-en').addClass('current-lang');
      $('.o-menu__languages .lang-item-en a').attr('href', eventDetail.next.url.href);
      $('.o-menu__languages .lang-item-fr a').attr('href', $(eventDetail.next.container).attr('data-translation'));
    }
    if($(document.body).hasClass('current-lang-fr')) {
      $('html').attr('lang', 'fr-FR');
      $('.o-menu__languages .current-lang:not(.lang-item-fr)').removeClass('current-lang').find('a').blur();
      $('.o-menu__languages .lang-item-fr').addClass('current-lang');
      $('.o-menu__languages .lang-item-fr a').attr('href', eventDetail.next.url.href);
      $('.o-menu__languages .lang-item-en a').attr('href', $(eventDetail.next.container).attr('data-translation'));
    }

  }

}

export { MenuGymnase };
